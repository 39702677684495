
.details__cont {
    display: flex;

}

.details__content {
    margin: 2.5rem auto;
}

/* .details__logos {
    display: flex;
    justify-content: space-around;
    border-bottom: 2rem;
} */

.details__logos {
    width: 99%;
margin: 0 auto;
}

@media (max-width: 943px) {
    .details__cont {
        flex-direction: column;
    }
}