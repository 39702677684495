.slick-prev:before,
.slick-next:before {
  color: black;
}
    .productheader__nav__main {
      width: 90%;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }
  .main__slider {
    margin: 0 auto;
    width: 90% ;
  }
  
  .product__nav__link {
    display: flex;
    justify-content: center;
    font-weight: 600;
    text-decoration: none;
    font-size: 1.2rem;
    color: #000;
  }
  .productnav__section {
    /* border: 3px solid red; */
    display: flex;
    justify-content: center;
    padding: 1rem 2rem;
    margin: 0 30px; 
  }

  .productnav__section:hover  {
    display: flex;
    background-color: #6c6b6b;
  }
  
  .Headermenuicon {
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-items: flex-end;
    display: none;
  }

  
  
  @media (max-width: 690px) {
    .productheader__nav__link {
      font-size: 1rem;
    }
    .productnav__section {
      margin: 0 auto;
    }
  }