.ourservices {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
    /* border: 5px solid green; */
}

.ourservices__subtitle {
    color: #fff;
    font-size: 1.7rem;
    align-self: center;
    /* border: 5px solid blue; */
}

.ourservices__title {
    color: #fff;
    font-size: 2rem;
    max-width: 35ch;
    margin-left: 9%;
    /* border: 5px solid magenta; */
}

.ourservices__list {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    /* border: 5px solid orange; */

}

.ourservices__sublist{
    display:flex;
    margin: 0 auto;
    /* border: 5px solid red;   */
    width: 80%;
    justify-content:space-around;

}

.services{
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    cursor: pointer;
    padding: 0.9rem;
    /* border: 5px solid pink; */
    width: 25rem;
    height: 30rem;
    background-color: white;
    border-radius: 25px;
}

.services:hover{
    background-color: #086ad7;
    color: white;
  
}

.services__name {
    text-decoration: none;
    color: #000;
    align-self: flex-start;
    font-size: 1.5rem;
    align-items: center;
    margin: 0 auto;
    /* border: 1px solid orange; */

}

.services__description {
    color: #000;
    /* border: 1px soild red; */
    font-size: 1rem;
    max-width: 40ch;
    align-self: center;
    text-align: center;

}

.services:hover .services__name {
    color: white; 
}

.services:hover .services__description {
    color: white;
}

.services__img {
    align-self: center;
    width: 100%;
   display: flex;
   justify-content: center;
}

@media (max-width: 1050px) {
    .ourservices__sublist {
        width: 95%;
    }
}

@media (max-width: 880px) {
    .services {
        width: 20rem;
        height: 25rem;
    }

    .services__name {
        font-size: 1.2rem;
    }

    .services__description {
        font-size: 0.9rem;
    }
}

@media (max-width: 700px) {
    .ourservices__sublist {
        flex-direction: column;
        align-items: center;
        /* margin-bottom: 1rem; */
    }

    .services__description {
        max-width: 60ch;
    }

    .services {
        margin-bottom: 4rem;
    }

    .ourservices__list {
        gap: 1rem;
    }
}

@media (max-width: 457px) {
    .ourservices__title {
        font-size: 1.2rem;
    }

    .ourservices__subtitle {
        font-size: 1.1rem;
    }
}

@media (max-width: 430px) {
    
    .services__description {
        font-size: 0.8rem;
    }
}

@media (max-width: 360px) {
    .services {
        width: 18rem;
        height: 23rem;    }
}