.contactbar {
    width: 100%;
    height: 15vh;
    padding: 0.5rem;
    position: fixed;
    background-color: #086ad7;
    z-index: 2; 
    /* border: 2px solid yellow; */
    /* display: flex; */
    justify-content: center;
}

.contactbar__container {
    display: flex;
    justify-content: space-between; 
    position: relative;
    z-index: 2;
    margin: 0 auto;
    width: 90%;
    background-color: #086ad7;
    /* border: 2px solid red; */
}

.contactbar__email__container {
    display: flex;
}

.contactbar__phone_container {
    display: flex;
}

.contactbar__phoneicon {
    /* border: 2px solid yellow; */
    margin-left: 5rem;
}

.contactbar__phone {
    margin-left: 1.2rem;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    /* border: 2px solid pink; */
}

.contactbar__email {
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

.contactbar__emailicon {
   margin: 0 auto;
   margin-right: 1.5rem;
   margin-top: 0.1rem;
}


@media (max-width: 912px) {
    .contactbar__email {
        align-self: center;
        font-size: 1.5rem;
    }
    .contactbar__phone {
        align-self: center;
        font-size: 1.5rem;
    }
}

@media (max-width: 731px) {
    .contactbar__email {
        align-self: center;
        font-size: 1rem;
    }
    .contactbar__phone {
       align-self: center;
        font-size: 1rem;
    }
    .contactbar__emailicon {
        display: none;
    }
    .contactbar__phoneicon {
        display: none;
    }
    .contactbar {
        height: 11vh;
    }
    .contactbar__container {
        margin-top: 0.5rem;
    }
}

@media (max-width: 363) {
    .contactbar__email {
        align-self: center;
        font-size: 1rem;
    }
    .contactbar__phone {
        align-self: center;
        font-size: 1rem;
    }
}

@media (max-width: 338px) {
    .contactbar__container {
        margin-top: 0;
    }
}