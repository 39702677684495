.rmm__section__title-1 {
    font-size: 2rem;
    max-width: 30ch;
    margin-bottom: 1rem;
}

.rmm__section__description {
    max-width: 60ch;
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 1.5rem;
}

.rmm__section__card {
    /* border: 5px solid red; */
    width: 90rem;
    display: flex;
    justify-content: space-around;
    margin: 0 auto 10rem;
    color: #fff;
}

.rmm__section__info {
    /* border: 5px solid green; */
    display: flex;
    flex-direction: column;
    width: 35rem;
    /* margin-left: 2rem; */
}

.rmm__section__image {
    width: 48rem;
    height: 25rem;
    /* border-radius: 1px; */
   /* border: 5px solid blue; */
    /* margin-right: 10rem; */
}

.image {
    object-fit: cover;
}

.rmm__section__image-2 {
    width: 35rem;
}

@media (max-width: 1420px) {
    .rmm__section__description {
        max-width: 70ch;
    }
    .rmm__section__title-1 {
        max-width: 60ch;
    }
    .rmm__section__card {
        width: 100%;
        margin-bottom: 1rem;
        flex-direction: column;
        align-items: center;
    }

    .rmm__section__info {
        width: 60rem;
        font-size: 2rem;
        margin-right: 0rem;
        margin-bottom: 3rem;
    }

    .rmm__section__image {
        width: 50rem;
        margin-bottom: 10rem;;
        display: flex;
        justify-content: center;
    }

    .rmm__section__image-2 {
        display: flex;
        justify-content: center;
        width: 40rem;
        margin-bottom: 3rem;
    }
}

@media (max-width:980px) {
    .rmm__section__info {
        width: 90%;
        font-size: 1.5rem;
    }
}

@media (max-width:860px){
    .rmm__section__image {
        width: 80%;
        height: 80%;
        margin-bottom: 10rem;
    }

    .rmm__section__image-2 {
        width: 85%;
    }
}

/* @media (max-width:600px){
    /* .rmm__section__image {
        width: 25rem;
        height: 15rem;
    } */

    /* .image {
        object-fit: fill;
    }
} */

@media (max-width: 480px) {
    .rmm__section__info {
        font-size: 1.2rem;
    }
}
