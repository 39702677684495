.scroll-to-top-button {
    position: fixed;
    bottom: 2rem;
    right: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.4rem 0.8rem;
    border-radius: 10000000px;
    cursor: pointer;
    z-index: 1000; 
  }
  
  .scroll-to-top-button:hover {
    background-color: #0056b3;
  }
  