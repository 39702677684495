.products__container {
    display: flex;
    flex-direction: column;
    justify-content:center;
    /* border: 5px solid red; */
}

/* .products__section {
    display: flex;
    flex-direction: column;
    border: 5px solid green;
} */

.products__sections {
    display: flex;
    margin: 0 auto;
    justify-content: space-around;
    margin-bottom: 5rem;
    max-width: 90rem;
    /* border: 5px solid purple; */
}

.products__sections-3 {
    display: flex;
    /* justify-content: start; */
    margin: 0 auto;
    /* justify-content: space-around; */
    margin-bottom: 5rem;
    max-width: 90rem;
    /* border: 5px solid purple; */
}

.products__card__container {
    border-radius: 15px 15px;
    background-color: #fff;
    color: #000;
}

.products__card {
    background-color: #fff;
    color: #000;
    border-radius: 0 0 15px 15px;
    width: 19rem;
    height: 17rem;
    padding: 0.5rem;
    cursor: pointer;
    transition: transform, 0.3s, ease, 0.5s;
}

.products__card-3 {
    background-color: #000;
    color: #000;
    border-radius: 0 0 15px 15px;
    width: 19rem;
    height: 17rem;
    padding: 0.5rem;
    cursor: pointer;
    transition: transform, 0.3s, ease, 0.5s;
}


.products__card:hover {
    background-color: #086ad7;
    color: #fff;
}

.products__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 5px solid grey; */
}
.products__item-3 {
    display: flex;
    color: black;
    flex-direction: column;
    align-items: center;
    /* border: 5px solid grey; */
}

.products__title {
    /* margin: 5rem 7rem; */
    margin: 5rem 0;
    margin-left: 7rem;
    font-size: 2rem;
    text-transform: capitalize;
    color: #fff;
    /* border: 5px solid red; */
}

.products__title-2 {
    text-align: center;
}

.products__description {
    text-align: center;
    max-width: 70ch;
    margin: 1rem 0;
    /* border: 5px solid brown; */
}

.products__img {
    /* border: 5px solid orange; */
    /* object-fit: fill; */
    width: 19rem;
    height: 15rem;
}
.products__btn {
    color:#000;
    background: none;
    border: none;
}

.products__link__container {
    /* border: 5px solid black; */
    padding: 1rem;
    background-color: #ccc;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.products__link__container-3 {
    /* border: 5px solid black; */
    padding: 1rem;
    background-color: #000;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.products__link {
    width: 100%;
    color: black;
    display: flex;
    justify-content: center;
    /* border: 5px solid black; */
    text-decoration: none;
}

.products__link__container:hover {
    background-color: #cccccc6c;
    color: #fff;
}

.products__link:hover {
    color:#fff;
}

@media (max-width: 975px) {
    .products__sections {
        flex-direction: column;
        margin-bottom: 0.5rem;
    }

    .products__card {
        width: 80%;
        height: 13.4rem;
        margin-bottom: 3rem;
    }

    .products__img {
        width: 80%;
        height: 20rem;
        /* margin-top: 1rem; */
    }
}

@media (max-width: 710px) {
    .products__card {
        width: 80%;
        height: 15rem;
        margin-bottom: 3rem;
    }
}

@media (max-width: 425px) {
    .products__img {
        width: 16rem;
        height: 15rem;
    }

    .products__title {
        margin-left: 1rem;
    }
    .products__card {
        width: 16rem;
        height: 20rem;
        margin-bottom: 3rem;
    }
    
}