.contact__header {
  padding: 1rem 0;
  background-color: #241A4D;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  margin-top: 3.5rem;
  position: fixed;
  top: 0;
  z-index: 2; 
  }

  .contact__header__nav__main {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .contactblack__bg {
    position: fixed;
    background-color: #000;
    width: 35rem;
    height: 15%;
    margin: 0 -10rem;
    /* border: 2px solid blue; */
    transform: skew(35deg);
    z-index: -1;
} 

.contact__header__title  {
  display: flex;
  font-size: 2.5rem;
  text-transform: uppercase;
  color:#fff;
  letter-spacing: 1.5px;
  /* border: 5px solid red; */
}

.contact__header__logo{
  /* border: 5px solid red; */
  max-width: 18rem;
  max-height: 10rem;
  z-index: 5;
  /* border: 5px solid pink; */
}

.contact__header__nav  {
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  gap: 2.5rem;
  margin-right: 2rem;
  /* border: 5px solid yellow; */
  justify-content: flex-end;
  flex: 1;
 max-width: 50%;
}

.contact__header__nav__link {
  text-decoration: none;
  color: #fff;
}

.footer__nav__item {
  display: flex;
  gap:1rem;
}

.contact__header__nav__link:hover  {
  color: orange;
  text-shadow: 1px 1px 2px rgba(8, 116, 240, 0.575), 0 0 25px rgb(8, 118, 243), 0 0 5px rgb(8, 106, 215);
}

.Header__menuicon {
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-items: flex-end;
  display: none;
}


/*nav*/
.contactnav__main {
  background-color: rgba(0, 0, 0, 0.8);
  /* border: 2px solid orange; */
  width: 30rem; 
  position: absolute;
  top: 100%; 
  right: 0;
  display: flex;
  display: none;
  justify-content: center;
}


.contactnav{
  /* border: 2px solid blueviolet; */
  display: flex;
  width: 100%;
  flex-direction: column;
}

.contactnav__link {
  padding: 1rem;
  margin: 0 auto;
  color: white;
}

.contactnav__link:hover {
  color: orange;
}

@media (max-width: 1600px) {
  .contactblack__bg {
    width: 31rem;
    height: 7.5rem;
  }
}

@media (min-width: 1600px) {
  .contactblack__bg {
    height: 7.5rem;
  }
}

@media (max-width: 1460px) {
  .contact__header__nav__link {
    display: none;
  }

  .contactblack__bg {
    height: 7.5rem;
  }

  .Header__menuicon {
    display: inline;
  }

  .contactnav__main {
    display: inline;
  }
}

@media (max-width: 1165px) {
  .contactblack__bg {
    height: 7.5rem;
  }
}

@media (max-width: 600px){
  .contactblack__bg {
    display: none;
  }
  .contact__header {
    background-color: #000;
}
}

@media (max-width: 440px) {
  .contact__header__title {
    font-size: 1.5rem;
  }
  .contact__header__logo {
    max-width: 10rem;
    max-height: 7rem;
  }
  .contactnav__main {
    width: 20rem; 
  }
  .contactnav__link {
    font-size: 1.5rem;
  }
}
