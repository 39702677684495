.homedetails__section {
    margin-top: -5rem;
    /* border: 5px solid white; */
}

.homedetails__container {
    display: flex;
    justify-content: space-around;
    /* border: 5px solid red; */
}

.homedetails__body {
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
    /* border: 5px solid purple; */
    max-width: 75ch;
    margin-top: 8rem;
}

.homedetails__title {
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 2rem;

}

.homedetails__description {
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 2rem;
}

.homedetails__form__container {
    display:flex;
    align-items: flex-start;
    width: 32rem;
    /* border: 5px solid white; */
}

.homedetails__form {
    display:flex;
    align-self:flex-start;
    /* border: 5px solid green; */
    margin: 0;
}

.homedetails__img {
    /* align-self: center; */
    margin: 0 auto;
    max-width: 24rem;
    aspect-ratio: 12/16;
    /* border: 5px solid magenta */
}

/* MEDIA QUERIES */

@media (max-width: 1330px) {
    .homedetails__container {
        /* margin: 20rem 0; */
        /* height: 120rem; */
        flex-direction: column;
    }

    .homedetails__form__container {
        width: 40rem;
        height:48rem;
        margin: 0 auto;
        transform: scale(5);
    }

    .homedetails__body {
        height: 65rem;
        margin-bottom: 5rem;
    }

    .homedetails__form {
        height: 43rem;
    }
    
}

@media (max-width: 665px) {

    .homedetails__form__container {
        width: 30rem;
        height:40rem;
        margin: 0 auto;
        transform: scale(5);
    }
    
    .homedetails__description {
        font-size: 1.5rem;
    }

    .homedetails__body {
        margin: 0 auto;
        padding-left: 0.5rem;
        max-width: 60ch;
        height: 50rem;
        margin-top: 7rem;
        margin-bottom: 2rem;
    }

    .homedetails__img {
        aspect-ratio: 10/8;
    }
}

@media (max-width: 540px) {
    .homedetails__form__container {
        width: 18rem;
        height:37rem;
        margin: 0 auto;
        transform: scale(5);
    }

    .homedetails__form {
        height: 40rem;
    }

    .homedetails__description {
        font-size: 1rem;
    }

    .homedetails__body {
        margin: 0 auto;
        max-width: 50ch;
        margin-bottom: 1.5rem;
        margin-top: 7rem;
        height: 40rem;
    }

    .homedetails__img {
        aspect-ratio: 10/8;
    }
}
