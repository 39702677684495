.footer{
    /* border: 5px solid green; */
    display: flex;
    flex-direction: column;
}

.footer__logo__title {
    width: 80%;
    /* margin-left: 3.5rem;
    margin-top: 1rem; */
    display:flex;
}

.footer__logo {
    /* border: 5px solid yellow; */
    /* margin-left: 6rem; */
    width: 15rem;
    height: 5rem;
    margin-bottom: 1rem;
}

.footer__title {
    /* border: 5px solid red; */
    align-self: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: 1.5px;
}

.footer__description {
    font-size: 1.4rem;
    max-width: 45ch;
    /* margin-left: 6rem; */
    margin-bottom: 1rem;
    color: #fff;
}

.footer__icons__list {
    width: 45%;
    /* margin-left: 4.3rem; */
    /* border: 5px solid purple; */
    display: flex;
    justify-content: space-between;
    color: #fff;
}

.footer__icon {
    font-size: 4rem;
    cursor: pointer;
}

.footer__icon:hover {
    transition: transform, 0.3s, ease, 0.9s;
    color: orange;
}

.footer__nav {
    width: 20%;
    font-size: 1.5rem;
    margin-top: 6rem;
    /* border: 5px solid red; */
    gap: 0.3rem;
    display: flex;
    flex-direction: column;
    color: #fff;
}

.footer__des__nav {
    /* border: 5px solid blue; */
    align-self: center;
    display: flex;
    margin-top: 2rem;
    width: 70%;
    /* padding-left:9.5rem; */
    /* gap: 10rem; */
    justify-content: space-between;
}

.footer__des__icons {
    display:flex;
    flex-direction: column;
}

.footer__arrow {
    margin-top: 0.35rem;
}

.footer__line {
    width:100%;
    height:0.03rem;
    background-color: #989797;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.footer__copywrite {
    color: #fff;
    font-style: italic;
    font-size: 0.7rem;
    margin: 0 auto;
    margin-bottom: 2rem;
}

.footer__nav__item {
    text-decoration: none;
    color: #fff;
    transition: transform, 0.3s, ease, 1s;
}

.footer__nav__item:hover {
    color: orange;
    transform: translateX(10px);
}

@media (max-width:1131px) {
    .footer__des__nav {
        width: 90%;
    }

    .footer__description {
        max-width: 40ch;
    }

}

@media (max-width:885px) {

    .footer__des__nav {
        gap: 1rem;
    }

    .footer__description {
        max-width: 60ch;
    }

    .footer__title {
        font-size: 1.2rem;
    }

    .footer__nav {
        width: 40%;
    }
}

@media (max-width: 725px) {

    .footer__des__nav {
        gap: 0rem;
    }

    .footer__des__nav {
        justify-content: none;
        flex-direction: column;
    }

    .footer__nav {
        width: 100%;
    }

    .footer__description {
        margin-left: 0;
        font-size: 1.3rem;
        padding-left: 1rem;
        max-width: 45ch;
    }

    .footer__title {
        font-size: 1.5rem;
    }

    .footer__icons__list {
        width: 55%;
        margin-left: 0;
        padding-left: 0.5rem;
    }
    .footer__logo {
        margin: 1rem;
    }
}

@media(max-width: 429px) {
.footer__logo {
    width: 10rem;
    height: 3rem;
}
}