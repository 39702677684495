.contacthero__header {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 30vh;
  margin-top: 11rem;
  background-position: center ;
}

.header {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    height: 40vh;
    margin-top: 6rem;
    background-position: center ;
}

@keyframes slideInLeft {
    from {
      transform: translateX(-60rem);
    }
  
    to {
      transform: translateX(0);
    }
  }

.header__container {
    padding: 2.5rem;
    margin-left: 4.5rem;
}

.header__title {
    animation: slideInLeft 1s ease 1s normal both;
    text-transform: capitalize;
    font-size: 5rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    color: #fff;
}

.header__route {
    animation: slideInLeft 1.2s ease 1.2s normal both;
    text-transform: capitalize;
    color: #fff;
}

@media (max-width: 1420px) {
  .header {
    height: 35vh;
  }
}

@media (max-width:806px ){
  .header {
    height: 15rem;
  }

  .header__title {
    font-size: 4rem;
  }
}

@media (max-width: 722px) {
  .header {
    height: 12rem;
  }

  .header__title {
    margin-top: 0.5rem;
    font-size: 2.5rem;
  }
}

@media (max-width: 590px) {
  .header__container {
    padding: 2.5rem 1rem;
    margin-left: 1rem;
  }

  .header__title {
    margin-top: 0rem;
    font-size: 2.5rem;
  }

  .header__route {
    font-size: 1.2rem;
  }
}

@media (max-width: 404px) {

  .header {
    height: 10rem;
  }

  .header__title {
    font-size: 2rem;
  }

}

@media (max-width: 368px) {
  .header__route {
    font-size: 1rem;
  }
}