.contact {
    margin-top: 3rem;
    margin-bottom: 4rem;
    /* border: 5px solid red; */
}

.contact__container {
    /* border: 5px solid green; */
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.contact__title {
    color: #fff;
    /* border: 5px solid blue; */
    margin: 0 auto;
    font-size: 2rem;
}

.contact__detailsform {
    /* border: 5px solid orange; */
    display: flex;
    flex-direction: column;
    width: 61rem;
    justify-content:space-around;
    align-self: center;
}

.contact__subfield {
    display: flex;
    justify-content: space-around;
    /* border: 5px solid purple; */
    width: 100%;
}

.contact__subfield2 {
    display: flex;
    /* border: 5px solid purple; */
    /* margin: 0 auto; */
    margin-left: 3rem;
    margin-top: 1rem;
    max-width: 90%;
    display: flex;
    width: 100%;
}

.contact__textfield {
    background-color: #fff;
    width: 40%;
    /* border: 5px solid green; */
    border-radius: 4px;
    z-index: 1;
}

.contact__textfield2 {
    background-color: #fff;
    width: 45%;
    /* border: 5px solid green; */
    border-radius: 4px;
    z-index: 1;
}

.contact__messagefield {
    background-color: #fff;
    border-radius: 5px;
    z-index: 1;
}

.contact__message {
    /* border: 5px solid palegreen; */
    width: 55rem;
    align-self: center;
}

.contact__button__container {
    margin: 0 auto;
}

.contact__button {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    font-size: 1.1rem;
    font-weight: 700;
    background-color: #086ad7;
    color: #fff;
    border: none;
}

.contactus__para {
    color: white;
    max-width: 70ch;
    margin: 0 auto;
    font-size: 1.7rem;
    text-align: center;
}

.contactus__para2 {
    color: white;
    max-width: 55ch;
    margin: 0 auto;
    font-size: 1.7rem;
    text-align: center;
}

.success__message {
    background-color: rgb(104, 192, 104);
    color: #fff;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    max-width: 30%;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .error__message {
    color:rgb(251, 86, 86);
    margin-bottom: 0.5rem;
    border-radius: 5px;
    max-width: 30%;
    margin-left: -1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  

@media (max-width: 1115px) {
    .contactus__para {
        margin: 0 auto;
        padding: 1rem;
        max-width: 65ch;
    }
    .contactus__para2 {
        margin: 0 auto;
        padding: 0.5rem;
        max-width: 65ch;
    }
}

@media (max-width: 995px) {
    .contact__detailsform {
        width: 100%;
    }

    .contact__message {
        width: 90%;
    }
    .contact__subfield2 {
       /* border: 3px solid pink; */
       max-width: 90%;
       /* margin: 0 auto; */
    }
}

@media(max-width:780px) {
    .contact__subfield2{
        margin-left: 2rem;
        /* margin: 0 auto; */
    }
}

@media (max-width: 650px) {
    .contact__subfield {
        flex-direction: column;
        align-items: center;
    }

    .contact__textfield {
        width: 90%;
        align-self: center;
    }

    .contact__textfield2 {
        width: 100%;
    }

    .contact__message {
        /* border: 5px solid palegreen; */
        width: 90%;
        align-self: center;
    }

    .contact__subfield2 {
        margin-left: 0rem;
        align-self: center;
    }
}

@media (max-width: 615px) {
    .contactus__para {
        font-size: 1.2rem;
    }
    .contactus__para2 {
        font-size: 1.2rem;
    }
}

@media(max-width: 345px) {
    .contactus__para {
        font-size: 1rem;
    }
    .contactus__para2 {
        font-size: 1rem;
    }
}