.navbar {
    /* border: 5px solid red; */
    margin-right: 2rem;
}

.navbar__container {
    width: 78%;
    margin-top: 3rem;
    margin-left: 6rem;
    /* border: 5px solid orange; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* width: 25%; */
}

.nav__link {
    color: black;
    font-weight: 600;
    background-color: #ccc;
    text-decoration: none;
    padding: 1rem 1.5rem;
}

.nav__link:hover {
    background-color: #086ad7;
    color: white;
    transition: transform, 0.3s, ease, 0.9s;
}

@media (max-width: 972px) {
    /* .nav__link {
        font-size: 0.8rem;
    } */

    .navbar__container {
        margin-left: 3rem;
        width: 90%;
    }
}

@media (max-width: 942px) {
    .nav__link {
        font-size: 0.8rem;
    }
    .navbar__container {
        margin-left: 3rem;
        width: 90%;
    }
}


@media (max-width: 749px) {
    .navbar__container {
        /* padding: 3rem 0 0 0; */
        align-self: center;
    }
    .nav__link {
        width: 100%;
        font-size: 0.8rem;
    }

    .navbar__container {
        margin-left: 2rem;
        width: 90%;
    }
}