.aboutus {
    /* padding: 5.2rem; */
    background-color: #000;
    margin-bottom: 5rem;
}

.aboutus__container {
    display: flex;
    margin-top: 6rem;
    margin-left: 5rem;
    justify-content: space-around;
    /* border: 5px solid red; */
}

.aboutus__info{
    margin-right: 1rem;
    color: #fff;
    /* border: 5px solid green; */
}

.aboutus__title {
    margin-bottom: 2rem;
    font-size: 2rem;
}

.aboutus__description {
    padding: 1.5rem;
    max-width: 50ch;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    /* font-style: italic; */
}

.aboutus___title2 {
    color: #fff;
    font-size: 1.8rem;
    max-width: 25ch;
    margin-bottom: 2rem;
}

.aboutus__card {
    width: 60%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    padding: 1rem;
    align-content: space-around;
    gap: 1.5rem;
}

.aboutus__card__icons {
    font-size: 3rem;
    align-self: center;
    color: #000;

}

.aboutus__card__info {
    font-size: 1.5rem;
    color: #000;

}

.aboutus__img__container{
    align-self: center;
    margin-right: 1rem;
    /* border: 5px solid blue; */
}

.aboutus__img {
    width: 40rem;
    object-fit: cover;
    aspect-ratio: 10/8;
}

/*MEDIA QUERIES*/

@media (max-width: 1430px) {
    .aboutus__container {
        margin-left: 4rem;
    }
    .aboutus__description {
        max-width: 45ch;
    }
    .aboutus__img {
        width: 100%;
    }
}

@media(max-width: 1130px) {
    .aboutus__info {
        margin-right: 1rem;
        margin-bottom: 2rem;
    }

    .aboutus__description {
        padding: 1rem;  
        max-width: 65ch;
    }

    .aboutus__container {
        margin-left: 5rem;
        flex-direction: column;
    }

    .aboutus__img__container {
        align-self: flex-start;
        margin-right: 1rem;
    }

    .aboutus__card {
        width: 20rem;
    }
}

@media (max-width: 650px) {
    .aboutus__container {
        margin-left: 2rem;
    }

    .aboutus__img {
        width: 85%;
    }
}

@media (max-width: 404px) {
    .aboutus__description {
        font-size: 1.2rem;
    }

    .aboutus___title2 {
        font-size: 1.5rem;
    }

    .aboutus__card {
        width: 15rem;
    }

    .aboutus__card__info {
        font-size: 1.2rem;
    }
}

