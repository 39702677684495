.mission {
    /* border: 5px solid red;  */
    padding-left: 8rem;  
    display: flex;
    justify-content: space-around;
    background-color: black;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 3rem;

}

.mission__vision {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    /* border: 5px solid greenyellow; */
    justify-content: space-around;
    width: 40rem;
}

.missionstatment {
    border: 1px solid #fff;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    width: 96%;

}

.vision {
    border: 1px solid #fff;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    width: 96%;   
    background-color: black;
}

.missionstatment__img {
    /* border: 5px solid orange; */
    height: 66%;
    margin-top: 0.6rem;
    margin-left: 0.4rem;
}

.missionstatment__content {
    /* border: 5px solid green; */
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    width: 78%;
    }

.mission__img__container {
    display: flex;
    /* border: 5px solid burlywood; */
    /* width: 75.5%; */
    height: 60dvh;
}

.mission__img {
    width: 34rem;
    object-fit: cover;
    aspect-ratio: 10/8;

}

.img {
    border: 1px solid gray;
    border-radius: 5px;
    /* width: 3rem; */
    height: 3rem;
    -webkit-transition: 0.6s ease-out;
    -moz-transition: 0.6s ease-out;
    transition: 0.6s ease-out;

}

.heading{
    font-size: 1.5rem;
}

.body {
    font-size: 1.2rem;
    font-weight: 600;
}

.vision:hover
{
    border: 2px solid #086ad7;
    .img {
        border: 1px solid #086ad7;
        -webkit-transform: rotateZ(720deg);
        -moz-transform: rotateZ(720deg);
        transform: rotateZ(720deg);
        box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411);
    
    }
} 

.missionstatment:hover 
{  
     border: 2px solid #086ad7;
    .img {
        border: 1px solid #086ad7;
        -webkit-transform: rotateZ(720deg);
        -moz-transform: rotateZ(720deg);
        transform: rotateZ(720deg);
        box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411);
    
    }
}

.vision__title {
    color: #fff;
}

.missionstatement__desc {
    color: gray;
}

/* MEDIA QUERIES */

@media (max-width: 1340px) {
    .mission {
        flex-direction: column;
        /* padding-left: 4rem; */
    }

    .mission__vision {
        margin: 0;
        margin-top: 4rem;
        width: 100%;
    }

    .missionstatment {
        margin-bottom: 2rem;
        /* height: 20dvh; */
    }

    .vision {
        /* height: 20dvh; */
    }

    .mission {
        padding-left: 2rem;
    }

    .mission__img {
        display: none;
    }

    .mission__img__container {
        display: none;
    }
}

@media (max-width: 650px) {
    .mission {
        padding-left: 1rem;
    }
}

@media (max-width: 560px) {
    .body {
        font-size: 1rem;
    }
}

@media (max-width: 342px) {
    .heading {
        font-size: 1.2rem;
    }

    .body {
        font-size: 0.9rem;
    }
}