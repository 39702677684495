.getquote {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin:0 auto 8rem;
    padding: 2rem 5rem;
    border-radius: 36px; 
    /* border: 5px solid green; */
}

.getquote:hover {
    box-shadow: 0 4px 8px 0 rgba(9, 110, 226, 0.404), 0 6px 20px 0 rgba(5, 120, 228, 0.411);
}

.getquote__container {
    display: flex;
    justify-content: space-around;
}

.getquote__title {
    /* border: 5px solid purple; */
    color: #fff;
    font-size: 2rem;
    max-width: 40ch;
}

.getquote__button__container {
    /* border: 5px solid red; */
    align-self: self-end;
    margin-bottom: 1rem;
}

.getquote__button {
    display: flex;
    border: none;
    background-color: #fff;
    font-family: inherit;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    color: #086ad7;
    font-weight: 600;
    font-size: 1rem;
}

.getquote__button:hover {
    background-color: #000;  
    color: #fff;
}

@media (max-width: 1117px) {
    .getquote__container {
        justify-content: none;
    }
    .getquote__title {
        font-size: 1rem;
    }
}


@media (max-width: 722px) {
    .getquote__title {
        font-size: 1.5rem;
        text-align: center;
        max-width: 40ch;
    }

    .getquote__container {
        flex-direction: column;
    }

    .getquote__button__container {
        align-self: center;
        margin-top: 1.5rem;
    }
}

@media (max-width: 560px) {
    .getquote__title {
        font-size: 1.15rem;
    }
}

@media (max-width: 461px) {
    .getquote {
        padding: 2rem 1.5rem;
    }
}