*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: 1.5;  
}

body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', sans-serif; */
  font-family: 'Book Antiqua', -apple-system, BlinkMacSystemFont, 'Open Sans', sans-serif;
}
  

button {
  transition: transform, 0.3s, ease, 0.5s;
}

button:hover {
  transform: translateY(-10px);
}
