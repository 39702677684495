.details__sec {
display: flex;
flex-direction: column;
}

.details__desc {
    padding: 1rem;
    /* border: 2px solid orange; */
    font-size: 1.3rem;
    max-width: 65ch;
    color: #fff;

}

.details__container {
    display: flex;
    flex-direction: column;
    /* border: 5px solid white; */
}

.details__title {
    color:#fff;
    padding: 1rem;
    font-size: 2rem;
}

.details__list {
    padding: 1.5rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
    max-width: 65ch;
    /* border: 2px solid orange; */
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;

}

.details__image {
    padding: 1rem;
    width: 40rem;
    /* border: 2px solid orange; */
    margin-top: 1rem;
}

@media (max-width: 943px) {
    .details__desc {
        max-width: 55ch;
        font-size: 1.1rem;
    }

    .details__list {
        max-width: 55ch;
        font-size: 1.1rem;
    }

    .details__image {
        padding: 1rem;
        width: 35rem;
        /* border: 2px solid orange; */
        margin-top: 1rem;
    }
}

@media (max-width: 565px) {
    .details__image {
        margin: 0 auto;
        width: 95%;
    }
}

