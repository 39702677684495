
.section__main__container {
    display: flex;
    justify-content: center;
    width: 100%;
    /* border: 5px solid cyan; */
}

.section__container {
    display: flex;
    justify-content: space-between;
    width: 90%;
    height: 50rem;
    /* margin: 0 auto; */
    background-color: #000;
    /* border:5px solid red; */
}

.section__sublist {
    display: flex;
    width: 100%;
    justify-content: space-around;
    /* border: 5px solid yellow; */
}

.service__section__title-1 {
    text-transform: uppercase;
    margin: 1rem auto;
    margin-top: 6rem;
    font-size: 6.8rem;
    max-width: 10ch;
    /* color:#fff; */
    color:#80808015;
    /* border: 5px solid blue; */
}
.service__section__title {
    text-transform: capitalize;
    margin: 1.5rem auto;
    margin-top: -6rem;
    font-size: 2rem;
    max-width: 25ch;
    color:#fff;
    /* color:#000; */
    /* border: 5px solid black; */
}

.section__title-2 {
    font-size: 1.5rem;
    text-align: center;
    display: flex;
    letter-spacing: 0.8px;
    justify-content:center;
    /* border: 5px solid green; */
}

.section {
    display: flex;
    padding: 1rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 0;
    /* border:5px solid purple;    */
}

.section__img {
    width: 100%;
    height: 30rem;
    /* border: 5px solid magenta */
}

.section__img-2 {
    /* border: 5px solid #000; */
    max-width:8rem;
    margin: 0 auto;
    padding: 1rem;
    -webkit-transition: 0.7s ease-out;
    -moz-transition:  0.7s ease-out;
    transition:  0.7s ease-out;
}

.section__card {
    width: 19rem;
    height: 20rem;
    padding: 1rem;
    margin-top: -7rem;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.404), 0 6px 20px 0 rgba(255, 255, 255, 0.411);
    /* border:5px solid blue; */
}

.section__description-2 {
    padding: 0.5rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    max-width: 45ch;
    color: grey;
    -webkit-transition: 0.7s ease;
    -moz-transition:  0.7s ease;
    transition:  0.7s ease;
    /* border: 5px solid grey; */
}

.section__card:hover .section__img-2{
    -webkit-transform: rotateZ(720deg);
    -moz-transform: rotateZ(720deg);
    transform: rotateZ(720deg);
}


/* MEDIA QUERIES */

@media (max-width: 1621px) {
    .section__card {
        width: 80%; 
    }
    
    .section__title-2 {
        font-size: 1.2rem;
    }
}

@media (max-width: 1438px) {
    .section__container {
        justify-content: center;
        width: 100%;
        height: 90%;
    }

    .section__main__container {
        margin-bottom: 8rem;
    }

    .section__sublist {
        flex-direction: column;
    }

    .section__img {
        width: 100%;
        height: 30rem;
        /* aspect-ratio: 9/16; */
        position: relative;
        /* border: 5px solid magenta */
    }

    .section__card {
        width: 80%;
        height: 20rem;
    }

    .section__img-2 {
        max-width: 7rem;
    }

    .section__title-2 {
        font-size: 1.5rem;
        display: flex;
        letter-spacing: 0.8px;
        justify-content:center;
        /* border: 5px solid green; */
    }

    .section__description-2 {
        margin: 0 auto;
    }
}

@media (max-width: 688px) {
    .section__container {
        flex-direction: column;
    }

    .section__img {
        padding: 1rem;
    }

    .service__section__title {
        margin-top: -3.5rem;
        font-size: 1.5rem;
    }

    .service__section__title-1 {
        font-size: 4rem;
    }
}

@media (max-width: 400px) {
    .section__img-2 {
        max-width: 6rem;
    }

    .section__title-2{
        font-size: 1rem;
    }
    .section__img {
         width: 90%;
         height: 18rem;
    }

    .section__card {
        width: 80%;
        height: 15rem;
    }

    .section__description-2 {
        font-size: 0.8rem;
        max-width: 60ch;
    }

    .service__section__title-1 {
        font-size: 3.5rem;
    }

    .service__section__title {
        padding-left: 0.8rem;
    }
}