.section__body {
  padding: 0 6.5rem;
  margin-top: 15rem;
  margin-bottom: 15rem;
  /* position: absolute; */
  display: inline-block;
  width: 100%;
  color: #fff;
  /* border: 5px solid red;  */

}

@keyframes slideInLeft {
  from {
    transform: translateX(-60rem);
  }

  to {
    transform: translateX(0);
  }
}

.slide__container {
  /* border: 5px solid blue; */
  height: 75rem;
  width: 100%;
  /* height: 100; */
}


.section__title {
  animation: slideInLeft 1s ease 1s normal both;
  line-height: 1;
  display: flex;
  color: #fff;
  font-size: 8rem;
  max-width: 15ch;
}

.section__description {
  animation: slideInLeft 1.2s ease 1.2s normal both;
  margin: 1rem 0 3rem;
  font-size: 1.5rem;
  max-width: 45ch;
}

.btn {
  width: 18rem;
  background: none;
  color: inherit;
  padding: 1.5rem;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.section__btn-1 {
  animation: slideInLeft 1.4s ease 1.4s normal both;
  border: none;
  background-color: #086ad7;
  color: #fff;
}

.section__btn-1:hover {
  background-color: #0404ad;
  box-shadow: 0 4px 8px 0 rgba(9, 110, 226, 0.404), 0 6px 20px 0 rgba(5, 120, 228, 0.411);
}
.section__btn-2 {
  color: #fff;
  animation: slideInLeft 1.3s ease 1.3s normal both;
  border: 1px solid #fff;
  margin-left: 2rem;
}

.section__btn-2:hover {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19);
  color: #000;
}


/*Media Queries*/

@media (max-width: 880px) {
  .section__container {
    width: '100%';
    height: '100vh';
  }

  .slide__container {
    /* border: 5px solid blue; */
    height: 60rem;
    width: 100%;
    /* height: 100; */
  }
  
  .section__body {
    padding: 1rem 1rem;
  }

  .section__title {
    padding: 0;
  }


}

@media (max-width: 687px) {
  .section__title {
    font-size: 5rem;
    padding: 0;
  }

  .section__description {
    font-size: 1.5rem;
    max-width: 45ch;
  }

  .btn {
    width: 13rem;
    padding: 1rem;
    font-size: 1rem;
  }

  .section__btn-2 {
    margin-left: 1rem;
  }
  
}

@media (max-width: 464px) {
  .buttons {
    display: flex;
    flex-direction: column;
  }

  .section__btn-2 {
    margin-left: 0;
    margin-top: 1rem;
  }

  .btn {
    padding: 1.3rem;
  }
}

@media (max-width: 448px) {
  .slide__container {
    width: 100%;
    height: 60rem;
  }

  .section__body {
    width: 40rem;
  }
  .section__title {
    font-size: 4rem;
    padding: 0;
  }

  .section__description {
    font-size: 0.99rem;
    margin: 1rem 0 2rem;
    max-width: 35ch;
  }

  /* .btn {
    width: 10rem;
    padding: 1rem;
    font-size: 0.8rem;
  } */
}

@media (max-width: 374px) {
  .section__body {
    padding: 0.5rem 0.5rem;
  }

  .slide__container {
    /* border: 5px solid blue; */
    height: 55rem;
    width: 100%;
    /* height: 100; */
  }

  .section__description {
    font-size: 1rem;
    margin: 1rem 0 2rem;
  }

  .section__title {
    font-size: 3rem;
    padding: 0;
  }

}




